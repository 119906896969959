<template>
    <!-- 租赁订单详情路由 -->
    <div class="order_lease_box">
        <!-- 用户信息标题 -->
        <div class="order_lease_title">用户信息</div>
        <!-- 用户信息 -->
        <div class="order_lease_user_info">
            <span>夏沫</span>
            <span>13720000000</span>
        </div>
        <!-- 租赁时间标题 -->
        <div class="order_lease_title">租赁时间</div>
        <!-- 租赁时间 -->
        <div class="order_lease_time">
            <el-steps direction="vertical">
                <el-step icon="el-icon-time">
                    <template slot="title">
                        <div class="order_lease_time_content">
                            <!-- 左侧借 -->
                            <div class="order_lease_time_content_loan">
                                <!-- 标题部分 -->
                                <div class="order_lease_time_content_title">
                                    <span>借</span>
                                </div>
                                <!-- 时间部分 -->
                                <div class="order_lease_time_content_time">
                                    <!-- 日期 -->
                                    <div class="order_lease_time_content_date">11-19</div>
                                    <!-- 时间 -->
                                    <div class="order_lease_time_content_week">
                                        <!-- 周 -->
                                        <span>周二</span>
                                        <!-- 时间 -->
                                        <span>09:00</span>
                                    </div>
                                </div>
                            </div>
                            <!-- 中间分界线 -->
                            <hr style="width: 100px;display: inline-block;vertical-align:middle;margin: 20px">
                            <!-- 右侧还 -->
                            <div class="order_lease_time_content_return">
                                <!-- 标题部分 -->
                                <div class="order_lease_time_content_title">
                                    <span>还</span>
                                </div>
                                <!-- 时间部分 -->
                                <div class="order_lease_time_content_time">
                                    <!-- 日期 -->
                                    <div class="order_lease_time_content_date">11-19</div>
                                    <!-- 时间 -->
                                    <div class="order_lease_time_content_week">
                                        <!-- 周 -->
                                        <span>周二</span>
                                        <!-- 时间 -->
                                        <span>09:00</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-step>
                <el-step icon="el-icon-location-outline">
                    <div slot="title">取件地址</div>
                    <template slot="description">
                        <div class="order_lease_time_address">广州市白云区同和街道天健创意园D5-1栋</div>
                    </template>
                </el-step>
                <el-step icon="el-icon-mobile-phone">
                    <div slot="title">联系方式</div>
                    <template slot="description">
                        <div class="order_lease_time_mobile">
                            <span>曾伟杰</span>
                            <span>13755846325</span>
                        </div>
                    </template>
                </el-step>
            </el-steps>
        </div>
        <!-- 订单详情标题 -->
        <div class="order_lease_title">订单详情</div>
        <!-- 订单详情 -->
        <div class="order_lease_detail">
            <el-row>
                <!-- 相片 -->
                <el-col :span="12">
                    <div class="order_lease_detail_goods_img">
                        <img :src="require('@/assets/images/camera/56fc835dNe349b797.jpg')" alt="">
                    </div>
                </el-col>
                <!-- 标题 -->
                <el-col :span="12">
                    <div class="order_lease_detail_goods_title">
                        佳能 EOS 6D Mark ll 专业单反相机
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <!-- 租赁费用 -->
                <el-col :span="12">
                    <div class="order_lease_detail_price_title">租赁费用</div>
                </el-col>
                <!-- 计算 -->
                <el-col :span="12">
                    <div class="order_lease_detail_price_box">
                        <!-- 数量 -->
                        <span>x1</span>
                        <!-- 价格 -->
                        <span>¥ 500.00</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 结算 -->
        <div class="order_lease_total_box">
            <!-- 标题 -->
            <span>订单总额：</span>
            <!-- 价格 -->
            <span>¥500.00</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* element-ui */
.order_lease_detail>.el-row{
    margin-top: 15px;
}

/* 租赁订单盒子 */
.order_lease_box{
    border: 1px solid #C0C4CC;
}
/* 标题 */
.order_lease_title{
    padding: 15px 20px;
    font-size: 17px;
    background-color: #E4E7ED;
}

/* 用户信息 */
.order_lease_user_info{
    padding: 15px 20px;
}
.order_lease_user_info>span + span{
    margin-left: 10px;
}

/* 租赁时间 */
.order_lease_time{
    padding: 15px 20px;
    height: 350px;
}
/* 借出时间 */
.order_lease_time_content_loan,
.order_lease_time_content_return{
    display: inline-block;
    vertical-align: middle;
}
/* 标题 */
.order_lease_time_content_title>span{
    display: inline-block;
    padding: 0 3px;
    color: #fff;
    background-color: #E6A23C;
}
/* 日期 */
.order_lease_time_content_time{
    margin-top: 10px;
}
.order_lease_time_content_date{
    font-size: 25px;
    font-weight: bold;
    color: #303133;
}
/* 星期 */
.order_lease_time_content_week{
    margin-top: 10px;
    color: #303133;
}
.order_lease_time_content_week>span + span{
    margin-left: 10px;
}
/* 地址 & 电话 */
.order_lease_time_address,
.order_lease_time_mobile{
    color: #303133;
}
.order_lease_time_mobile>span + span{
    margin-left: 10px;
}

/* 订单详情 */
.order_lease_detail{
    padding: 15px 20px;
}
/* 图片 */
.order_lease_detail_goods_img{
    position: relative;
    width: 50px;
    height: 50px;
    border: 1px solid #DCDFE6
}
.order_lease_detail_goods_img>img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
/* 商品标题 */
.order_lease_detail_goods_title,
.order_lease_detail_price_box{
    text-align: right;
}
/* 租赁费用 */
.order_lease_detail_price_box> span + span{
    margin-left: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #F56C6C;
}

/* 结算 */
.order_lease_total_box{
    background-color: #C0C4CC;
    padding: 15px 20px;
    text-align: right;
}
.order_lease_total_box>span + span{
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    color: red;
}
</style>
